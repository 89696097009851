
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import MaterialStocks, {
  exportMaterialStockData,
  exportPDFMaterialStocks,
  getMaterialStockCountById,
} from "@/core/data/materialStock";
import {
  MaterialStock,
  getMaterialStocks,
  deleteMaterialStock,
} from "@/core/data/materialStock";
import { useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";

export default defineComponent({
  name: "MaterialStock-listing",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const materialStockCount = ref<any>({});
    const checkedMaterialStock = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    const selectedMaterial = computed(() => {
      return route.params.id;
    });
    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllMaterialStock = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<MaterialStock>>(MaterialStocks);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);
    const getListItems = function (pageNumber) {
      console.log("selectedMaterial.value", selectedMaterial.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getMaterialStocks(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedMaterial.value.toString()
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("MaterialStocks Listing", [
        "Apps",
        "MaterialStocks",
      ]);
      getListItems(1);
      getMaterialStockCountById(route.params.id).then(function (data: any) {
        console.log(data);
        materialStockCount.value = {
          in: data?.inQty?.qty__sum,
          out: data?.outQty?.qty__sum,
          waste: data?.wasteQty?.qty__sum,
        };
      });
    });

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedMaterialStock.value.forEach((item) => {
        deleteMaterialStock(item);
      });
      checkedMaterialStock.value.length = 0;
    };
    const exportFewSites = () => {
      exportMaterialStockData(
        selectAllMaterialStock.value
          ? "all"
          : checkedMaterialStock.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your MaterialStock has been exported.",
          "success"
        );
      });
    };
    const exportPDFMaterialStocksHanlder = () => {
      exportPDFMaterialStocks(
        selectAllMaterialStock.value
          ? "all"
          : checkedMaterialStock.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your MaterialStock has been exported.",
          "success"
        );
      });
    };
    const deleteMaterialStockItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMaterialStock(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your MaterialStock has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addMaterialStock = (item) => {
      if (item.id) {
        router.push({
          name: "apps-stock-material-details",
          params: { id: selectedMaterial.value, stockId: item.id },
        });
      } else {
        router.push({ name: "apps-stock-material-add" });
      }
    };

    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const hasCreate = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasEdit = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasDelete = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "QTY",
          key: "qty",
          sortable: false,
        },
        {
          name: "Remark",
          key: "remark",
          sortable: false,
        },
        {
          name: "Type",
          key: "stock_type",
          sortable: false,
        },
        {
          name: "Material",
          key: "material_data",
          sortable: false,
        },
      ];
      if (hasEdit.value || hasDelete.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });

    const onClickBack = () => {
      router.back();
    };

    return {
      addMaterialStock,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteMaterialStock,
      getMaterialStocks,
      deleteMaterialStockItem,
      search,
      searchItems,
      checkedMaterialStock,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedMaterial,
      asyncUser,
      exportFewSites,
      exportPDFMaterialStocksHanlder,
      selectAllMaterialStock,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
      onClickBack,
      materialStockCount,
    };
  },
});
